import axios from "axios";

const BASE_URL = process.env.NEXT_PUBLIC_API_URL;

// CREATE =>  POST: add a new customer to the server
export function login(data) {
  return axios.post(`${BASE_URL}/login`, data);
}

//Regsite user
export function register(data) {
  return axios.post(`${BASE_URL}/register`, data);
}

//Forgot Password
export function forgotPassword(data) {
  return axios.post(`${BASE_URL}/forgot-password`, data);
}

//Reset Password from forgot login link
export function resetPassword(data) {
  return axios.post(`${BASE_URL}/reset-password`, data);
}


//Create user with public form
export function validateUser(data) {
  return axios.post(`${BASE_URL}/validate-user`, data);
}


//Create user with public form
export function preLoginGetOrderId(data) {
  return axios.post(`${BASE_URL}/prelogin-get-order-id`, data);
}

