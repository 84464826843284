import Link from 'next/link';
import { useForm } from 'react-hook-form';
import InputField from '../Common/InputField';
import Button from '../Common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../../redux/Auth/authActions';
import { login } from '../../../redux/Auth/authSlice';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Swal from 'sweetalert2';
import Logo from '../Logo';

function LoginForm() {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const router = useRouter();
  const [btnLoader, setBtnLoader] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (auth.isLogin) {
      const redirectUrl = router.query.redirect;
      if (redirectUrl) {
        router.push(redirectUrl);
      } else {
        router.push('/dashboard');
      }
    }
  }, [auth.isLogin, router]);

  useEffect(() => {
    setBtnLoader(false);
    if (auth.error) {
      Swal.fire('Error!', auth.error, 'error');
    }
  }, [auth.error]);

  const onSubmit = (data) => {
    console.log(data);
    setBtnLoader(true);
    dispatch(
      loginUser({
        username: data.username,
        password: data.password,
      }),
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      // className={Object.keys(errors).length ? "was-validated" : ""}
    >
      <div className="mb-3">
        <Logo />
      </div>
      <h1 className="h3 mb-3 fw-normal">Sign in to your LinkApp account</h1>
      <div className="mb-3">
        <InputField placeholder="Enter Username" type="text" label="Username" name="username" register={register} validation={{ required: true, minLength: '3' }} errors={errors?.username} />
      </div>

      <div className="mb-3">
        <InputField label="Password" placeholder="Enter Password" name="password" type="password" register={register} validation={{ required: true, minLength: '3' }} errors={errors?.password} />
      </div>

      <Button className="w-100 btn-lg btn-primary" btntext="Sign in" loading={btnLoader} />
      <div className="text-center  text-muted mt-3">
        <Link href="/forgot-password">Forgot password?</Link>
      </div>
      <div className="text-center  text-muted mt-3">
        Don&apos;t have an account?{' '}
        <Link href="/register">
          <a>Create one</a>
        </Link>
      </div>
    </form>
  );
}

export default LoginForm;
