// import { ErrorMessage } from "formik";
import React from 'react';

// function InputField({ className, ...props }) {
//   console.log('props', {...props})
//   const { inputstyle } = props;

//   if (inputstyle === "FLOATING")
//     return (
//       <React.Fragment>
//         <div className="form-floating mb-3">
//           <input
//             className={`form-control ${
//               props.errors && props.touched ? "is-invalid" : ""
//             }`}
//             {...props}
//             id="floatingPassword"
//           />
//           <label htmlFor="floatingPassword">{props.label}</label>
//           {/* <ErrorMessage
//             className="invalid-feedback"
//             name={props.name}
//             component="div"
//           /> */}
//         </div>
//       </React.Fragment>
//     );

//   return (
//     <React.Fragment>
//       <label className="form-label">{props.label}</label>
//       <input
//         className={`form-control form-control-solid ${className} ${
//           props.errors && props.touched ? "is-invalid" : ""
//         }`}
//         {...props}
//         {...props.register(props.label, {props.required})}
//       />
//       {/* <ErrorMessage
//         className="invalid-feedback"
//         name={props.name}
//         component="div"
//       /> */}
//     </React.Fragment>
//   );
// }

const InputField = ({ label, name, register, validation, className, errors, ...props }) => {
  const { type, disabled, handleChange } = { ...props };
  console.log('props', props);
  console.log('errors', errors);
  // console.log('validation', validation);
  return (
    <>
      <label className="form-label">{label}</label>
      <input
        type={type}
        className={`form-control form-control-solid ${className} ${errors ? 'is-invalid' : ''}`}
        {...register(name, { ...validation })}
        // onChange={handleChange}
        disabled={disabled}
      />

      {/* Validation */}
      {errors?.type === 'required' && <div className="invalid-feedback">This field is required.</div>}
      {errors?.type === 'minLength' && <div className="invalid-feedback">Min length should be {validation.minLength}.</div>}
      {errors?.type === 'maxLength' && <div className="invalid-feedback">Max length should be {validation.maxLength}.</div>}
      {errors?.type === 'pattern' && <div className="invalid-feedback">{validation.pattern.message}</div>}
      {errors?.type === 'validate' && <div className="invalid-feedback">{errors.message}.</div>}
      {errors?.type === 'min' && <div className="invalid-feedback">{errors.message}.</div>}
      {errors?.type === 'max' && <div className="invalid-feedback">{errors.message}.</div>}
      {errors?.type === 'custom' && <div className="invalid-feedback">{errors.message}.</div>}
    </>
  );
};

export default InputField;
