import authSlice from "./authSlice";
import * as requestFromServer from "./authCrud";

const { actions } = authSlice;

// export const loginUser = function (data) {
//   return function (dispatch) {
//     return requestFromServer.login(data).then( res  => {
//       const { token } = res.data;
//       dispatch(actions.login({ token: token }));
//     });
//   };
// };
export const loginUser = function (data) {
  return async function (dispatch) {
    try {
      dispatch(actions.startCall());
      const res = await requestFromServer.login(data);
      console.log("In dispatch");
      if (res.data.status === "S") {
        const { token } = res.data;
        window.localStorage.setItem("authToken", token);
        dispatch(actions.login({ authToken: token }));
      }else{     
        throw new Error(res.data.message)
      }
    } catch (error) {
      dispatch(actions.catchError({ errorMessage: error.message }));
      console.log("In after dispatch");
    }
  };
};

export const registerUser = function (data) {
  return async function (dispatch) {
    try {
      dispatch(actions.startCall());
      const res = await requestFromServer.register(data);
       if (res.data.status === "S") {
        const { token } = res.data;
        window.localStorage.setItem("authToken", token);
        dispatch(actions.register({ authToken: token }));
      }else{     
        throw new Error(res.data.message)
      }
    } catch (error) {
      dispatch(actions.catchError({ errorMessage: error.message }));
      console.log("In after dispatch");
    }
  };
};