function Button(props) {
  const { className, btntext, loading, type, ...otherprops } = props;
  const classNamesList = className;

  return (
    <button type={type} className={`btn ${classNamesList}  ${loading ? 'button--loading' : ''}`} disabled={loading} {...otherprops}>
      <span className="button__text">{btntext}</span>
    </button>
  );
}

Button.defaultProps = {
  type: 'submit',
};
export default Button;
