import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function AuthSidebar(props) {
  const [todayDate, setTodayDate] = useState('');
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    setTodayDate(new Date().toISOString());
    console.log('Authside bar rendered');
  }, []);

  return (
    <div className="">
      <div className="row g-0">
        <div className="col-md-4">
          <div className="auth-right-sidebar text-center d-flex justify-content-center align-items-center">
            <div>
              {/* {' '}
              AuthSidebar is here {todayDate} 
              <br /> */}
              <div
                style={{
                  position: 'relative',
                  width: '300px',
                  height: '600px',
                }}
              >
                <Image src="/1links-mobile-template.svg" width="100%" height={'500'} layout="fill" alt="Mobile Template Image" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-8 d-flex align-items-center auth-left-box">
          <div className="w-500 m-auto ">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
